// var cryptojs = require('crypto-js');
import * as cryptojs from 'crypto-js'
var test = {
    encrypt (obj) { 
    //  console.log("in encrypt") 
      var encryptedBase64Key = process.env.VUE_APP_API_PARAMS_KEY;
      var parsedBase64Key = cryptojs.enc.Base64.parse(encryptedBase64Key);
      var encryptedText = 
        cryptojs.AES.encrypt(JSON.stringify(obj), parsedBase64Key,{
                  mode: cryptojs.mode.ECB,
                  padding: cryptojs.pad.Pkcs7
              }).toString()
      
      return encryptedText
    },
    decrypt(text){
      var encryptedBase64Key = process.env.VUE_APP_API_PARAMS_KEY;
      var parsedBase64Key = cryptojs.enc.Base64.parse(encryptedBase64Key);
      var decryptedData = cryptojs.AES.decrypt( text, parsedBase64Key, {
      mode: cryptojs.mode.ECB,
      padding: cryptojs.pad.Pkcs7
      } );
      var decryptedText = decryptedData.toString( cryptojs.enc.Utf8 );
      // console.log("decryptedText",decryptedText)
      return decryptedText
    },
  }
  
  export default test