<template>
  <div id="inspire">

    <Header></Header>
    <v-content>

      <br>
      <v-row justify="center" style="width: 100%;">
        <v-card elevation="3" class="card-css mx-3" style="width: 50%;">
          <v-card-text>
            <br>
            <v-row no-gutters style="font-size: 20px; align-items: start;" justify="start" align="start">

              <v-col cols="12" sm="6" md="6">
                Name :<h4>{{ profile_data.name }}</h4>
                <br>

                Country : <h4>{{ profile_data.country }}</h4>
                <br>
                Number : <h4>{{ profile_data.mobile_number }}</h4>
                <br>

                City : <h4>{{ profile_data.city }}</h4>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                Email : <h4>{{ profile_data.email }}</h4>

                <br>

                Pin :<h4>{{ profile_data.pin }}</h4>
                <br>

                State :<h4>{{ profile_data.state }}</h4>
                <br>

                Address : <h4>{{ profile_data.address }}</h4>

              </v-col>
            </v-row>

            <br>
          </v-card-text>

        </v-card>
      </v-row>




      <br>
      <center>
        <v-row justify="center" align="center" style="width: 70%;">

          <v-col class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3" v-for='navLink in navLinks'
            :key="navLink.text">
            <span style=" cursor: pointer;" @click="sublink_page(navLink.subLinks, navLink.text)">

              <v-card style="margin: 5px; height: 200px" class="card-css1">
                <v-card-text>
                  <center>
                    <v-avatar size="80px" class="mt-4  p1">
                      <v-icon v-if="navLink.icon" size="50px" color="#ffff" class="fas fa-fire fa-3x">{{ navLink
                        .icon }}</v-icon>
                      <v-icon v-else size="50px" color="#ffff" class="fas fa-fire fa-3x">mdi-library</v-icon>
                    </v-avatar>

                  </center>

                </v-card-text>
                <v-card-title class="justify-center">
                  <h4>{{ navLink.text }}</h4>
                </v-card-title>

              </v-card>
            </span>
          </v-col>
        </v-row>
      </center>

    </v-content>

    <Footer :drawer="$store.state.drawer"></Footer>
  </div>
</template>
<script>
import Sidebar from "../components/shared/Sidebar";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import axios from "axios";
import crypto from "@/service/crypto.js";
export default {
  data: () => ({
    dialog: false,
    drawer: null,
    learner_course: "",
    user_type: "",
    navLinks: [],
    profile_data: null,

  }),
  components: {
    Sidebar: Sidebar,
    Header: Header,
    Footer: Footer,
  },
  mounted() {
    this.rolelink()

  },
  methods: {

    sublink_page(subLinks, text) {

      let data2 = { subLinks: subLinks, text: text }
      let enc = crypto.encrypt(data2);
      localStorage.setItem("jhhjbyj", text)

      this.$router.push({
        name: "sublink",
        params: { id: enc },

      });
    },

    rolelink() {
      axios
        .get("/Home/getRoleWithRoleLink")
        .then(res => {
          this.navLinks = res.data.navLinks;
          this.profile_data = res.data.profile_data;

        })
        .catch(error => {
          window.console.log(error);
          this.$router.push("/");
        });
    }
  },

};
</script>


<style scoped>
.button {
  margin: 1rem;
}

.table-data {
  margin: 8px;
}

.card-css1 {
  background: linear-gradient(to right, darkgray, rgb(181, 148, 148));
  /* background:radial-gradient(circle, rgba(254,235,243,1) 0%, rgb(220, 217, 217) 100%); */
  margin-left: 10px;
  border-radius: 10px !important;
  ;
  /* border-radius: 14px  */
  /* position: relative; */
}

.card-css1:hover {
  border: 1px solid rgb(46, 50, 38);
  background: rgba(171, 184, 176, 0.506) !important;
  color: #000 !important;
}

.p1 {
  background: #080708;
}



.card-css1:hover .p1 {

  border: none;
  border-radius: 100%;
  color: white;
  background: rgb(141, 151, 112);

}

.card-css {
  background: linear-gradient(to right, darkgray, rgb(181, 148, 148));
  /* background:radial-gradient(circle, rgba(254,235,243,1) 0%, rgb(220, 217, 217) 100%); */


  ;
}

@media only screen and (max-width: 600px) {
  body {
    width: 100%;
    background-color: red;
  }
}

.card-css:hover {}
</style>